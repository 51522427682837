@import '../partials/_colors.scss';
@import '../partials/_fonts.scss';

.banner-container {
    background: $primary-blue;
    width: 536px;
    position: relative;
    height: 656.44px;
    bottom: 35px;

    .header {
        color: #fff;
        font-family: 'SourceSansPro-Bold';
        padding: 70px 0;
        text-align: center;
    }
    
    .big-icon {
        position: relative;
        padding: 0px 70px;
    }

    .banner-footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 25px;
        margin: auto;
        width: max-content;
        img {
            padding: 0 10px;
            cursor: pointer;
        }
    }
}
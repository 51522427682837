@import '../partials/_colors.scss';
@import '../partials/_fonts.scss';

.register-container {
    background: #fff;
    width: 1088px;
    height: 584px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media(max-width: 1024px) {
        flex-direction: column-reverse;
        width: 80%;
        height: max-content;
        .banner-container {
            display: none;
        }
        .register-form-section {
            width: auto !important;
        }
    }


    .register-form-section {
        margin: 0 100px;
        padding: 50px 0;
        width: 269px;
        .register-form-head {
            font-family: "SourceSansPro-Regular";
            font-size: 24px;
            text-align: center;
            .logo-text {
                vertical-align: middle;
                padding: 0 5px;
            }
        }

        .register-body {
            .register-body-info {
                padding: 25px 0;
                .register-page-message {
                    font-size: 18px;
                    color: $primary-light;
                    text-align: center;
                }
            }
            .register-form-container {
                .register-form {
                    .register-form-fields {
                        .base-input-container {
                            margin: 0 0 30px 0;
                        }
                        .btn {
                            margin: 20px 0 0 0;
                        }
                    }
                }
            }
            .register-info {
                font-size: 16px;
                text-align: center;
                padding: 30px 0;
                .link-to-register {
                    color: $primary-green;
                    text-decoration: none;
                }
            }
            
            .mobile-register-links {
                display: none;
                @media (max-width: 1024px) {
                    display: block;
                    .social-links {
                        position: absolute;
                        bottom: 15px;
                        left: 0;
                        right: 0;
                        margin: auto;
                        width: fit-content;
                        padding: 20px 0 0 0;
                        cursor: pointer;
                        img {
                            padding: 0 15px;
                        }
                    }
                }
            }
        }
    }
}
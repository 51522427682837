@font-face {
    font-family: "SourceSansPro-Black";
    src: url("../../fonts/SourceSansPro-Black.ttf");
    font-style: normal;
}

@font-face {
    font-family: "SourceSansPro-Bold";
    src: url("../../fonts/SourceSansPro-Bold.ttf");
    font-style: normal;
}

@font-face {
    font-family: "SourceSansPro-Light";
    src: url("../../fonts/SourceSansPro-Light.ttf");
    font-style: normal;
}

@font-face {
    font-family: "SourceSansPro-Extra-Light";
    src: url("../../fonts/SourceSansPro-ExtraLight.ttf");
    font-style: normal;
}

@font-face {
    font-family: "SourceSansPro-Regular";
    src: url("../../fonts/SourceSansPro-Regular.ttf");
    font-style: normal;
}

@font-face {
    font-family: "SourceSansPro-Italic";
    src: url("../../fonts/SourceSansPro-Italic.ttf");
    font-style: normal;
}
@import '../../styles/partials/_colors.scss';
@import '../../styles/partials/_fonts.scss';

// Base Input
.base-input-container {
    position: relative;

    .base-input {
        border: none;
        border-bottom: 1px solid $primary-light;
        border-radius: 0;
        outline: none;
        color: $dark-text;
        width: 100%;
        padding: 5px;

        &:hover {
            border-bottom: 2px solid $primary-blue;
        }

        &:focus {
            background-color: #fff;
        }

        &::placeholder {
            color: $primary-light;
        }
    }

    .icon {
        position: absolute;
        right: 2px;
        top: 3px;
        z-index: 2;
        cursor: pointer;
        background: #fff;
        height: 31px;

        img {
            position: relative;
            right: 5px;
        }

        span {
            font-family: "SourceSansPro-Extra-Light";
            color: $primary-light;
            position: relative;
            top: 2px;
        }
    }

    .error-message {
        color: $error-red;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    }
}
// Base Input

// Base Button
.base-button-container {
    .btn {
        background: $primary-green;
        width: 100%;
        color: #fff;
        border-radius: 0px;

        .icon {
            position: relative;
            right: 10px;
            bottom: 2px;
            vertical-align: middle;
        }
    }
}
// Base Button
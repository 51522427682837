@import '../partials/_colors.scss';
@import '../partials/_fonts.scss';

.login-container {
    background: #fff;
    width: 1088px;
    height: 584px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media(max-width: 1024px) {
        flex-direction: column-reverse;
        width: 80%;
        height: max-content;
        padding: 20px 0;
        .banner-container {
            display: none;
        }
        .login-form-section {
            width: 80% !important;
            margin: auto !important;
        }
    }

    .login-form-section {
        margin: 0 100px;
        padding: 50px 0;
        width: 269px;
        .login-form-head {
            font-family: "SourceSansPro-Regular";
            font-size: 24px;
            text-align: center;
            .logo-text {
                vertical-align: middle;
                padding: 0 5px;
            }
        }
        .login-body {
            .login-body-info {
                padding: 25px 0;
                .login-page-message {
                    font-size: 18px;
                    color: $primary-light;
                    text-align: center;
                }
                .link-to-register {
                    text-align: center;
                    color: $primary-blue;
                    text-decoration: none;
                }
            }
            .login-form-container {
                .login-form {
                    .login-form-fields {
                        .base-input-container {
                            margin: 30px 0;
                        }
                        .btn {
                            margin: 20px 0 0 0;
                        }
                    }
                }
            }
        }
        .login-footer {
            text-align: center;
            margin: 40px 0 0 0;
            .link-to-forgot-password {
                color: $dark-text;
                text-decoration: none;
            }
        }
    }
}
.mobile-login-links {
    display: none;
    @media (max-width: 1024px) {
        display: block;
        .social-links {
            position: absolute;
            bottom: 15px;
            left: 0;
            right: 0;
            margin: auto;
            width: fit-content;
            padding: 20px 0 0 0;
            cursor: pointer;
            img {
                padding: 0 15px;
            }
        }
    }
}